import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage/plugin-tech-radar';
  
  export class AceTechRadar implements TechRadarApi {
    async load(): Promise<TechRadarLoaderResponse> {
      // if needed id prop can be used to fetch the correct data
  
      // const data = await fetch('https://mydata.json').then(res => res.json());
      const data = require('../radar.json');
  
      // maybe you'll need to do some data transformation here to make it look like TechRadarLoaderResponse
  
      return data;
    }
  }